import React from "react";
// import { Link } from "react-router-dom";

export default function GetInTouch(){
    return(
        <>
        <div className="container">
            <div className="grid grid-cols-1 text-center">
                <h3 className="mb-6 md:text-2xl text-xl font-medium">¿Tiene Alguna Pregunta ? Ponte en Contacto!</h3>

                <p className="text-slate-400 max-w-xl mx-auto">¡Contáctanos hoy y descubre cómo podemos llevar tu negocio al siguiente nivel!.</p>
            
                <div className="relative mt-10">
                  <a href="#contact" className="btn bg-color-arco-light hover:bg-color-arco-dark border-color-arco-light hover:border-color-arco-dark text-white rounded-md">Contáctanos</a>
                </div>
            </div>
        </div>
        </>
    )
}
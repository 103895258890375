import React, { useState, useEffect } from "react";
import './assets/css/tailwind.css';
import './assets/scss/tailwind.scss';

import './assets/scss/icons.scss';

import {
  Route,
  Routes
} from "react-router-dom";


import Index from "./pages/index.js";
import Loader from "./component/Loader";
import PortfolioDetailSambito from "./pages/portfolio-detail-sambito.js";
import PortfolioDetailPonce3 from "./pages/portfolio-detail-ponce-3.js";
import PortfolioDetailSemgroup from "./pages/portfolio-detail-semgroup.js";
import PortfolioDetailTorres from "./pages/portfolio-detail-torres.js";

export default function App() {

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.documentElement.setAttribute('dir', 'ltr');
    handleRouteChange();
  }, []);


  // Route change method
  const handleRouteChange = () => {
    setLoading(false);
  }

  return (
    <>
      {loading && <Loader />}
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/index" element={<Index />} />
        <Route path="/portfolio-detail-sambito" element={<PortfolioDetailSambito/>} />
        <Route path="/portfolio-detail-ponce-3" element={<PortfolioDetailPonce3/>} />
        <Route path="/portfolio-detail-semgroup" element={<PortfolioDetailSemgroup/>} />
        <Route path="/portfolio-detail-torres" element={<PortfolioDetailTorres/>} />
      </Routes>
    </>
  );

}

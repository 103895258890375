import React from "react";
import Navbar from "../component/navbar";
import GetInTouch from "../component/GetInTouch";
import image1 from '../assets/images/portfolio/logo/logo-grande/ponce-3.webp'
import Footer from "../component/Footer";
import Switcher from "../component/Switcher";

export default function PortfolioDetail(){
    const isDetailPage = true;

    return(
        <>
        <Navbar isDetailPage={isDetailPage}/>
        <section className="py-28 w-full table relative bg-[url('../../assets/images/bg/banner-ponce-3.webp')] bg-top bg-no-repeat">
            <div className="absolute inset-0 bg-slate-950/80"></div>

            <div className="container">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 className="mb-3 text-3xl leading-normal font-medium text-white">Plan Estratégico</h3>

                    <ul className="list-none">
                        <li className="inline text-white/60 me-2"> <span className="text-white">Cliente :</span> Ponce 3 </li>
                    </ul>
                </div>
            </div>
        </section>

        <section className="relative md:py-24 py-16">
            <div className="container md:pb-24 pb-16">
                <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                    <div className="lg:col-span-5 md:col-span-6">
                        <div className="grid grid-cols-1 gap-[30px]">
                            <img src={image1} className="rounded-md" alt=""/>
                        </div>
                    </div>

                    <div className="lg:col-span-7 md:col-span-6">
                        <div className="sticky top-20">
                            <div className="grid lg:grid-cols-12 grid-cols-1 gap-[30px]">
                                <div className="lg:col-span-12">
                                    <div className="work-details">
                                        <h4 className="text-xl font-medium mb-3 border-b border-gray-100 dark:border-gray-700 pb-3">Detalle del Proyecto :</h4>
                                        <p className="text-slate-400">En ARCO Estrategias facilitamos la construcción del modelo de negocio y el plan estratégico de la empresa, además de implementar un sistema de control a la gestión gerencial. Gracias a estas acciones, la empresa pudo alinear su estructura organizacional, mejorar la eficiencia operativa y tomar decisiones gerenciales con mayor precisión, lo que impulsó su crecimiento sostenido y su capacidad para adaptarse a nuevas oportunidades de mercado.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           <GetInTouch/>
        </section>
        <Footer/>
        <Switcher/>
        </>
    )
}
import React, { useState } from "react";
import { Link } from "react-router-dom";

import pmsImage from "../assets/images/metodologias/pms-metodologia.webp";
import smcImage from "../assets/images/metodologias/smc-metodologia.webp";
import soeImage from "../assets/images/metodologias/soe-metodologia.webp";

// import CTABackground from "../assets/images/bg/cta.png";

export default function Services() {
  const team = [
    {
      title: 'Planificación Estratégica',
      icon: 'chess-knight',
      subtext: `Te acompañamos en cada paso del proceso de definición de tu estrategia utilizando el Strategy Model 
                Canvas®. Desde el análisis de tu situación actual hasta la creación de un plan de acción claro y 
                ejecutable, nuestro propósito es formular una estrategia que garantice un crecimiento continuo y rentable.`
    },
    {
      title: 'Modelo de Negocio',
      icon: 'arrows-to-dot',
      subtext: `Facilitamos el análisis y definición de un nuevo modelo de negocio cuando las condiciones del entorno, la 
                competencia, la tecnología o las fuerzas de no mercado vuelven inviable el anterior. Nuestro enfoque creativo 
                te permite definir los recursos y capacidades, explorar nuevas oportunidades y fortalecer tu posición competitiva.`
    },
    {
      title: 'Performance Management Insights',
      icon: 'chart-simple',
      subtext: `Realizamos un diagnóstico exhaustivo para evaluar el nivel de implementación de un sistema de gestión en tu empresa, 
                abarcando aspectos operativos, estratégicos y culturales. Con este análisis, podrás definir las acciones necesarias 
                para implementar una cultura de gestión enfocada en resultados e implementar de manera efectiva la estrategia, alcanzando 
                un rendimiento superior y sostenible a largo plazo.`
    },
    {
      title: 'Acompañamiento en el Comité Ejecutivo',
      icon: 'handshake',
      subtext: `Brindamos asesoría estratégica personalizada a tu equipo ejecutivo, ayudándolos a alinear sus decisiones 
                con la estrategia organizacional y a priorizar metas clave. A través de nuestro acompañamiento cercano, 
                no solo apoyamos la toma de decisiones críticas, sino que también garantizamos un seguimiento constante 
                de proyectos clave y las prioridades más importantes. Medimos el cumplimiento de las metas, lo que permite 
                a tu equipo tomar las mejores decisiones posibles y avanzar con claridad hacia los objetivos organizacionales.`
    },
    {
      title: 'SOE - Sistema Operativo de Estrategia',
      icon: 'chart-line',
      subtext: `Implementamos un Sistema Operativo de Estrategia (SOE) que integra metodologías avanzadas de gestión, 
                permitiéndote supervisar y ajustar continuamente la ejecución de tu estrategia empresarial. Esto asegura 
                una alineación constante y un control efectivo de la gestión, además de un seguimiento continuo de la 
                estrategia, garantizando la mejora continua de todos los aspectos clave del negocio.`
    },
    {
      title: 'Capacitación y Desarrollo',
      icon: 'medal',
      subtext: `Ofrecemos capacitaciones personalizadas y prácticas que fortalecen las competencias clave de tu equipo. 
                Nos enfocamos en el desarrollo de habilidades estratégicas, de liderazgo y gestión de alto nivel, para 
                garantizar que tu empresa cuente con el talento necesario para alcanzar sus objetivos. `
    }
  ]

  const [expandedIndex, setExpandedIndex] = useState(null);
  const toggleExpansion = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null); // Si ya está expandido, contrae el texto
    } else {
      setExpandedIndex(index); // Expande el texto correspondiente
    }
  };

  return (
    <>
      {/* Start */}
      <section className="relative md:py-24 py-16 active" id="features">
        <div className="container lg mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 pb-8 items-center">
            <div>
              <h6 className="text-color-arco-dark
 text-base font-medium uppercase mb-2">Nuestros Servicios Profesionales</h6>
              {/* <h3 className="mb-4 md:text-2xl text-xl font-semibold dark:text-white md:mb-0">Solución perfecta para sus <br /> Negocios</h3> */}
            </div>

            <div>
              <p className="text-zinc-800 dark:text-slate-300 max-w-xl">
                En Arco Estrategias, asesoramos y guiamos a las empresas en la formulación e implementación de sus estrategias,
                acompañándolas en su ejecución para maximizar el rendimiento empresarial y asegurar resultados sostenibles.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">
            {team.map((item, index) => (
              <div key={index} className={`features p-6 ${index % 2 === 0 ? "hover:shadow-xl hover:shadow-slate-100 dark:hover:shadow-slate-800" : "shadow-xl shadow-slate-100 dark:shadow-slate-800"} transition duration-500 rounded-3xl mt-8`}>
                <div className="w-20 h-20 bg-color-arco-light/5 text-color-arco-dark
 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                  <i className={`fa-solid fa-${item.icon}`}></i>
                </div>

                <div className="content mt-7">
                  <Link to="#" className="text-lg hover:text-color-arco-dark
 dark:text-white dark:hover:text-color-arco-dark
 transition-all duration-500 ease-in-out font-medium">{item.title}</Link>
                  <p className="text-zinc-800 mt-3">
                    {expandedIndex === index ? item.subtext : `${item.subtext.slice(0, 100)}...`}
                  </p>

                  <div className="mt-5">
                    <button
                      onClick={() => toggleExpansion(index)}
                      className="btn btn-link hover:text-color-arco-dark
 dark:hover:text-color-arco-dark
 after:bg-color-arco-light dark:text-white transition duration-500">
                      {expandedIndex === index ? 'Leer menos' : 'Leer más'} <i className="uil uil-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="container md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-color-arco-dark
 text-base font-medium uppercase mb-2">Metodologías que Aplicamos</h6>
            {/* <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Optimización Estratégica para el Éxito</h3> */}

            <p className="text-zinc-800 dark:text-slate-300 max-w-xl mx-auto">
              En Arco Estrategias, contamos con metodologías propias y registradas en el Instituto Ecuatoriano de Propiedad Intelectual (IEPI),
              diseñadas para proporcionar enfoques estratégicos y de gestión personalizados y de alto impacto. Estas metodologías están integradas
              en todos nuestros servicios de consultoría, permitiéndonos alinear y optimizar las estrategias de nuestros clientes de manera efectiva
              y sostenible.
            </p>
          </div>

          <div className="grid grid-cols-1 mt-8">
            <div className="timeline relative">
              <div className="timeline-item">
                <div className="grid sm:grid-cols-2">
                  <div className="">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={pmsImage} className="h-64 w-96 object-contain" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 md:mt-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">PMS®</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-zinc-800">El Performance Management System® es un conjunto de fundamentos, pasos y prácticas de gestión integrados; enfocados en las personas; que conectan la estrategia con lo que hacen en el "día a día"; y mejora su capacidad de tener alto performance. Simultáneamente facilita la adaptación de las personas, la mejora y el aprendizaje organizacional. Permite desarrollar y testear las habilidades gerenciales en el "mundo real"</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="md:order-1 order-2">
                    <div className="event event-description-left ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">SMC®</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-zinc-800">
                        El SMC® facilita la planificación estratégica y la toma de decisiones, 
                        asegurando un enfoque integral y coherente. Se basa en 11 elementos clave: 
                        Misión, Valores, Propósito Superior, Unidades de Negocio, Factores Clave de Éxito,
                        FODA, Acciones Estratégicas, Metas Estratégicas, Visión, Tendencia Futura y Ventajas Distintivas
                      </p>
                    </div>
                  </div>
                  <div className="md:order-2 order-1">
                    <div className="duration duration-right md:ms-7 relative">
                      <img src={smcImage} className="h-64 w-96 object-contain" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="mt-4 mt-sm-0">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={soeImage} className="h-64 w-96 object-contain" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 mt-sm-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">SOE®</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-zinc-800">El Sistema Operativo de Estrategia® es un sistema informático innovador diseñado para permitir a los
                        usuarios controlar y dar seguimiento a la estrategia de su empresa.
                        Consolida las metodologías de SMC® y PMS® para crear una sinergia
                        entre la definición de la estrategia y la gestión gerencial</p>
                        <br/>
                        <a href="https://www.soe.la" target="_blank" rel="noreferrer" className="btn bg-color-arco-light hover:bg-color-arco-dark border-color-arco-light hover:border-color-arco-dark text-white rounded-md">Descubre SOE®</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

}

import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { Link as Link2 } from "react-router-dom";

import LogoLight from "../assets/images/logo-light.png";
import LogoDark from "../assets/images/logo-dark.png";

export default function Navbar({ navdark, isDetailPage }) {
  const [isOpen, setMenu] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", windowScroll);
    window.scrollTo(0, 0);
    return () => {
      window.removeEventListener("scroll", windowScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenu(!isOpen);
  };

  const closeMenu = () => {
    setMenu(true);
  };

  function windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("is-sticky");
    } else {
      navbar.classList.remove("is-sticky");
    }
  }

  return (
    <>
      <nav className="navbar" id="navbar">
        <div className="container flex flex-wrap items-center justify-end">
          {navdark && (
            <Link2 className="navbar-brand" to="/">
              <span>
                <img src={LogoDark} className="inline-block dark:hidden" alt="" />
                <img src={LogoLight} className="hidden dark:inline-block" alt="" />
              </span>
            </Link2>
          )}
          {!navdark && (
            <Link2 className="navbar-brand" to="/">
              <span className="inline-block dark:hidden">
                <img src={LogoDark} className="l-dark" alt="" />
                <img src={LogoLight} className="l-light" alt="" />
              </span>
              <img src={LogoLight} className="hidden dark:inline-block" alt="" />
            </Link2>
          )}

          <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
            <button
              type="button"
              className="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
              onClick={toggleMenu}
            >
              <span className="sr-only">Navigation Menu</span>
              <i className="mdi mdi-menu mdi-24px"></i>
            </button>
          </div>

          <div
            className={`${isOpen ? "hidden" : "block"} navigation lg_992:order-1 lg_992:flex`}
            id="menu-collapse"
          >
            <ul className={`navbar-nav ${navdark ? "" : "nav-light"}`} id="navbar-navlist">
              {isDetailPage ? (
                <>
                  <a className="nav-item" href="/index#home" onClick={closeMenu}>
                    <span className="nav-link">Inicio</span>
                  </a>
                  <a className="nav-item" href="/index#about" onClick={closeMenu}>
                    <span className="nav-link">Quiénes Somos</span>
                  </a>
                  <a className="nav-item" href="/index#features" onClick={closeMenu}>
                    <span className="nav-link">Servicios Profesionales</span>
                  </a>
                  <a className="nav-item" href="/index#portfolio" onClick={closeMenu}>
                    <span className="nav-link">Casos de Éxito</span>
                  </a>
                  <a className="nav-item" href="/index#contact" onClick={closeMenu}>
                    <span className="nav-link">Contáctanos</span>
                  </a>
                </>
              ) : (
                <>
                  <Link
                    className="nav-item"
                    to="home"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-72}
                    onClick={closeMenu}
                  >
                    <span className="nav-link">Inicio</span>
                  </Link>
                  <Link
                    className="nav-item"
                    to="about"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-72}
                    onClick={closeMenu}
                  >
                    <span className="nav-link">Quiénes Somos</span>
                  </Link>
                  <Link
                    className="nav-item"
                    to="features"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-62}
                    onClick={closeMenu}
                  >
                    <span className="nav-link">Servicios Profesionales</span>
                  </Link>
                  <Link
                    className="nav-item"
                    to="portfolio"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-62}
                    onClick={closeMenu}
                  >
                    <span className="nav-link">Casos de Éxito</span>
                  </Link>
                  <Link
                    className="nav-item"
                    to="contact"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-100}
                    onClick={closeMenu}
                  >
                    <span className="nav-link">Contáctanos</span>
                  </Link>
                </>
              )}
            </ul>
            <ul className="list-none foot-icon md:text-end text-center">
              <li className="inline ms-1"><a href="https://www.linkedin.com/company/arco-estrategias-consulting/" target="_blank" rel="noreferrer" className="btn btn-icon btn-sm border rounded-md border-slate-700 dark:border-slate-800 hover:border-color-arco-light bg-slate-800 dark:bg-gray-900 hover:bg-color-arco-light dark:hover:bg-color-arco-light text-gray-400 hover:text-white"><i className="uil uil-linkedin" title="Linkedin"></i></a></li>
              <li className="inline ms-1"><a href="https://www.instagram.com/arco_estrategias?igsh=MW1qem1qYmxsbnF3eQ%3D%3D&utm_source=qr" target="_blank" rel="noreferrer" className="btn btn-icon btn-sm border rounded-md border-slate-700 dark:border-slate-800 hover:border-color-arco-light bg-slate-800 dark:bg-gray-900 hover:bg-color-arco-light dark:hover:bg-color-arco-light text-gray-400 hover:text-white"><i className="uil uil-instagram align-middle" title="instagram"></i></a></li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

import React from "react";
import Navbar from "../component/navbar";
import GetInTouch from "../component/GetInTouch";
import image1 from '../assets/images/portfolio/logo/logo-grande/semgroup.webp'
// import image2 from '../assets/images/portfolio/2.jpg'
// import image3 from '../assets/images/portfolio/3.jpg'
import Footer from "../component/Footer";
import Switcher from "../component/Switcher";

export default function PortfolioDetail(){
    const isDetailPage = true;

    return(
        <>
        <Navbar isDetailPage={isDetailPage}/>
        <section className="py-28 w-full table relative bg-[url('../../assets/images/bg/banner-semgroup.webp')] bg-top bg-no-repeat">
            <div className="absolute inset-0 bg-slate-950/80"></div>

            <div className="container">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 className="mb-3 text-3xl leading-normal font-medium text-white">Plan Estratégico</h3>

                    <ul className="list-none">
                        <li className="inline text-white/60 me-2"> <span className="text-white">Cliente :</span> Semgroup </li>
                        {/* <li className="inline text-white/60"> <span className="text-white">Fecha :</span> 5th May, 2024</li> */}
                    </ul>
                </div>
            </div>
        </section>

        <section className="relative md:py-24 py-16">
            <div className="container md:pb-24 pb-16">
                <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                    <div className="lg:col-span-5 md:col-span-6">
                        <div className="grid grid-cols-1 gap-[30px]">
                            <img src={image1} className="rounded-md" alt=""/>
                            {/* <img src={image2} className="rounded-md" alt=""/>
                            <img src={image3} className="rounded-md" alt=""/> */}
                        </div>
                    </div>

                    <div className="lg:col-span-7 md:col-span-6">
                        <div className="sticky top-20">
                            <div className="grid lg:grid-cols-12 grid-cols-1 gap-[30px]">
                                <div className="lg:col-span-12">
                                    <div className="work-details">
                                        <h4 className="text-xl font-medium mb-3 border-b border-gray-100 dark:border-gray-700 pb-3">Detalle del Proyecto :</h4>
                                        <p className="text-slate-400">En ARCO Estrategias brindamos nuestro servicio de planificación estratégica a las unidades de negocio de Semgroup, enfocándonos en la alineación de sus metas y acciones con la visión de largo plazo. Esto permitió una mayor cohesión entre las distintas áreas, optimizando la utilización de recursos y fomentando un enfoque más eficiente y proactivo en la toma de decisiones. Como resultado, Semgroup ha logrado una mejora significativa en su competitividad y una visión más clara para el desarrollo de sus negocios a largo plazo.</p>
                                        {/* <p className="text-slate-400 mt-2">Además, utilizamos metodologías efectivas que aseguran la alineación de cada acción con la visión y misión de la organización. Este enfoque integral permite a Calvin Carlo anticiparse a los desafíos del mercado y posicionarse como un líder en su sector, garantizando así un impacto significativo en sus resultados a largo plazo.</p> */}
                                    </div>
                                </div>
                                
                                {/* <div className="lg:col-span-7">
                                    <div className="bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 p-6 rounded-md">
                                        <h5 className="text-lg font-medium border-b border-gray-100 dark:border-gray-700 pb-3 mb-3">Información:</h5>
                                        <dl className="grid grid-cols-12 mb-0">
                                            <dt className="md:col-span-4 col-span-5 mt-2">Cliente :</dt>
                                            <dd className="md:col-span-8 col-span-7 mt-2 text-slate-400">Calvin Carlo</dd>

                                            <dt className="md:col-span-4 col-span-5 mt-2">Categoría :</dt>
                                            <dd className="md:col-span-8 col-span-7 mt-2 text-slate-400">Web Design</dd>

                                            <dt className="md:col-span-4 col-span-5 mt-2">Fecha :</dt>
                                            <dd className="md:col-span-8 col-span-7 mt-2 text-slate-400">23rd July, 2023</dd>

                                            <dt className="md:col-span-4 col-span-5 mt-2">Sitio Web :</dt>
                                            <dd className="md:col-span-8 col-span-7 mt-2 text-slate-400">www.yourdomain.com</dd>

                                            <dt className="md:col-span-4 col-span-5 mt-2">Localización :</dt>
                                            <dd className="md:col-span-8 col-span-7 mt-2 text-slate-400">3/2/64 Mongus Street, UK</dd>
                                        </dl>  
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           <GetInTouch/>
        </section>
        <Footer/>
        <Switcher/>
        </>
    )
}
import React from "react";

//Imagenes Sector Industrial
import Arcacontinental from "../assets/images/customers/industrial/arcacontinental.webp";
import Acquetech from "../assets/images/customers/industrial/acquetech.webp";
import Colgate from "../assets/images/customers/industrial/colgate.webp";
import Tonicorp from "../assets/images/customers/industrial/tonicorp.webp";
import Grupasa from "../assets/images/customers/industrial/grupasa.webp";
import Tractomaq from "../assets/images/customers/industrial/tractomaq.webp";
import Tugalt from "../assets/images/customers/industrial/tugalt.webp";
import Nutec from "../assets/images/customers/industrial/nutec.webp";
import Funadametz from "../assets/images/customers/industrial/funadametz.webp";
import Reysac from "../assets/images/customers/industrial/reysac.webp";
import Poligroup from "../assets/images/customers/industrial/poligroup.webp";
import Oletnat from "../assets/images/customers/industrial/oletnat.webp";
import Offset from "../assets/images/customers/industrial/offset.webp";
import Sika from "../assets/images/customers/industrial/sika.webp";
import Dajahu from "../assets/images/customers/industrial/dajahu.webp";

//Imagenes Sector Comercial
import Americanclassics from "../assets/images/customers/comercial/americanclassics.webp";
import Apy from "../assets/images/customers/comercial/apy.webp";
import Calvinklein from "../assets/images/customers/comercial/calvinklein.webp";
import Difare from "../assets/images/customers/comercial/difare.webp";
import Electrofacil from "../assets/images/customers/comercial/electrofacil.webp";
import Expoplaza from "../assets/images/customers/comercial/expoplaza.webp";
import Graiman from "../assets/images/customers/comercial/graiman.webp";
import Mobil from "../assets/images/customers/comercial/mobil.webp";
import Pdvsa from "../assets/images/customers/comercial/pdvsa.webp";
import Pedral from "../assets/images/customers/comercial/pedral.webp";
import Tommyhilfiger from "../assets/images/customers/comercial/tommyhilfiger.webp";

//Imagenes Sector Comercial
import Alcaldiaguayaquil from "../assets/images/customers/publico/alcaldiaguayaquil.webp";
import Alcaldiamanta from "../assets/images/customers/publico/alcaldiamanta.webp";

//Imagenes Sector Agricola
import Agrow from "../assets/images/customers/agricola/agrow.webp";
import Delmonte from "../assets/images/customers/agricola/delmonte.webp";
import Ecokakao from "../assets/images/customers/agricola/ecokakao.webp";
import Pescanova from "../assets/images/customers/agricola/pescanova.webp";
import Unicol from "../assets/images/customers/agricola/unicol.webp";

//Imagenes Sector Servicos
import Apl from "../assets/images/customers/servicios/4pl.webp";
import Andersen from "../assets/images/customers/servicios/andersen.webp";
import Corpei from "../assets/images/customers/servicios/corpei.webp";
import Covigon from "../assets/images/customers/servicios/covigon.webp";
import Dhl from "../assets/images/customers/servicios/dhl.webp";
import Ecuatorianosuiza from "../assets/images/customers/servicios/ecuatorianosuiza.webp";
import Gadere from "../assets/images/customers/servicios/gadere.webp";
import Gea from "../assets/images/customers/servicios/gea.webp";
import Maint from "../assets/images/customers/servicios/maint.webp";
import Marurigrey from "../assets/images/customers/servicios/marurigrey.webp";
import Mym from "../assets/images/customers/servicios/mym.webp";
import Norlop from "../assets/images/customers/servicios/norlop.webp";
import Remar from "../assets/images/customers/servicios/remar.webp";
import Sambito from "../assets/images/customers/servicios/sambito.webp";
import Seginus from "../assets/images/customers/servicios/seginus.webp";
import Semgroup from "../assets/images/customers/servicios/semgroup.webp";
import Solca from "../assets/images/customers/servicios/solca.webp";
import Sweetycoffee from "../assets/images/customers/servicios/sweetycoffee.webp";
import Torrestorres from "../assets/images/customers/servicios/torrestorres.webp";
import Vive from "../assets/images/customers/servicios/vive.webp";

//Imagenes Sector Financiero
import Bancobolivariano from "../assets/images/customers/financiero/bancobolivariano.webp";
import Bolsadevalores from "../assets/images/customers/financiero/bolsadevalores.webp";
import Globalbank from "../assets/images/customers/financiero/globalbank.webp";

//Imagenes Sector Educativo
import Catolica from "../assets/images/customers/educativo/catolica.webp";
import Cedia from "../assets/images/customers/educativo/cedia.webp";
import Copei from "../assets/images/customers/educativo/copei.webp";
import Copol from "../assets/images/customers/educativo/copol.webp";
import Espae from "../assets/images/customers/educativo/espae.webp";

//Imagenes Sector Gremios
import Aite from "../assets/images/customers/gremio/aite.webp";
import Anchan from "../assets/images/customers/gremio/anchan.webp";
import Anecacao from "../assets/images/customers/gremio/anecacao.webp";
import Apcsa from "../assets/images/customers/gremio/apcsa.webp";
import Camaracomercio from "../assets/images/customers/gremio/camaracomercio.webp";
import Cipem from "../assets/images/customers/gremio/cipem.webp";
import Institutopesca from "../assets/images/customers/gremio/institutopesca.webp";
import Malecon200 from "../assets/images/customers/gremio/malecon200.webp";

const imagesCustomersServicios = [
    Apl,
    Andersen,
    Corpei,
    Covigon,
    Dhl,
    Ecuatorianosuiza,
    Gadere,
    Gea,
    Maint,
    Marurigrey,
    Mym,
    Norlop,
    Remar,
    Sambito,
    Seginus,
    Semgroup,
    Solca,
    Sweetycoffee,
    Torrestorres,
    Vive
]
const imagesCustomersFinanciero = [
    Bancobolivariano,
    Bolsadevalores,
    Globalbank,
    Bancobolivariano,
    Bolsadevalores,
    Globalbank,
    Bancobolivariano,
    Bolsadevalores,
    Globalbank,
    Bancobolivariano,
    Bolsadevalores,
    Globalbank,
    Bancobolivariano,
    Bolsadevalores,
    Globalbank,
]
const imagesCustomersEducativo = [
    Catolica,
    Cedia,
    Copei,
    Copol,
    Espae,
    Catolica,
    Cedia,
    Copei,
    Copol,
    Espae,
    Catolica,
    Cedia,
    Copei,
    Copol,
    Espae,
]
const imagesCustomersGremios = [
    Aite,
    Anchan,
    Anecacao,
    Apcsa,
    Camaracomercio,
    Cipem,
    Institutopesca,
    Malecon200,
    Aite,
    Anchan,
    Anecacao,
    Apcsa,
    Camaracomercio,
    Cipem,
    Institutopesca,
    Malecon200
]


const imagesCustomersIndustrial = [
    Arcacontinental,
    Acquetech,
    Colgate,
    Tonicorp,
    Grupasa,
    Tractomaq,
    Tugalt,
    Nutec,
    Funadametz,
    Reysac,
    Poligroup,
    Oletnat,
    Offset,
    Sika,
    Dajahu
];

const imagesCustomersComercial = [
    Americanclassics,
    Apy,
    Calvinklein,
    Difare,
    Electrofacil,
    Expoplaza,
    Graiman,
    Mobil,
    Pdvsa,
    Pedral,
    Tommyhilfiger
];

const imagesCustomersPublico = [
    Alcaldiaguayaquil,
    Alcaldiamanta,
    Alcaldiaguayaquil,
    Alcaldiamanta,
    Alcaldiaguayaquil,
    Alcaldiamanta,
    Alcaldiaguayaquil,
    Alcaldiamanta,
    Alcaldiaguayaquil,
    Alcaldiamanta,
    Alcaldiaguayaquil,
    Alcaldiamanta,
];

const imagesCustomersAgricola = [
    Agrow,
    Delmonte,
    Ecokakao,
    Pescanova,
    Unicol,
    Agrow,
    Delmonte,
    Ecokakao,
    Pescanova,
    Unicol,
    Agrow,
    Delmonte,
    Ecokakao,
    Pescanova,
    Unicol
];


export default function Customers() {
    return (
        <>
            <section className="relative md:py-8 py-8  bg-white dark:bg-slate-800 active" id="customers">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Sector Industrial</h3>
                    </div>
                </div>
                <div className="relative m-auto w-[60%] overflow-hidden bg-white before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] after:content-['']">
                    <div className="animate-infinite-slider-customer-industrial flex w-[calc(150px*15*2)] space-x-8">
                        {imagesCustomersIndustrial.map((logo, index) => (
                            <div
                                className="slide flex w-[150px] items-center justify-center"
                                key={index}
                            >
                                <img src={logo} className="max-w-full h-auto" alt={`customer-logo-${index}`} />
                            </div>
                        ))}
                        {imagesCustomersIndustrial.map((logo, index) => (
                            <div
                                className="slide flex w-[150px] items-center justify-center"
                                key={index}
                            >
                                <img src={logo} className="max-w-full h-auto" alt={`customer-logo-${index}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="relative md:py-8 py-8  bg-white dark:bg-slate-800 active" id="customers">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Sector Comercial</h3>
                    </div>
                </div>
                <div className="relative m-auto w-[60%] overflow-hidden bg-white before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] after:content-['']">
                    <div className="animate-infinite-slider-customer-comercial flex w-[calc(150px*11*2)] space-x-8">
                        {imagesCustomersComercial.map((logo, index) => (
                            <div
                                className="slide flex w-[150px] items-center justify-center"
                                key={index}
                            >
                                <img src={logo} className="max-w-full h-auto" alt={`customer-logo-${index}`} />
                            </div>
                        ))}
                        {imagesCustomersComercial.map((logo, index) => (
                            <div
                                className="slide flex w-[150px] items-center justify-center"
                                key={index}
                            >
                                <img src={logo} className="max-w-full h-auto" alt={`customer-logo-${index}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="relative md:py-8 py-8  bg-white dark:bg-slate-800 active" id="customers">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Sector Agrícola</h3>
                    </div>
                </div>
                <div className="relative m-auto w-[60%] overflow-hidden bg-white before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] after:content-['']">
                    <div className="animate-infinite-slider-customer-agricola flex w-[calc(150px*15*2)] space-x-8">
                        {imagesCustomersAgricola.map((logo, index) => (
                            <div
                                className="slide flex w-[150px] items-center justify-center"
                                key={index}
                            >
                                <img src={logo} className="max-w-full h-auto" alt={`customer-logo-${index}`} />
                            </div>
                        ))}
                        {imagesCustomersAgricola.map((logo, index) => (
                            <div
                                className="slide flex w-[150px] items-center justify-center"
                                key={index}
                            >
                                <img src={logo} className="max-w-full h-auto" alt={`customer-logo-${index}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="relative md:py-8 py-8  bg-white dark:bg-slate-800 active" id="customers">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Sector Servicios</h3>
                    </div>
                </div>
                <div className="relative m-auto w-[60%] overflow-hidden bg-white before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] after:content-['']">
                    <div className="animate-infinite-slider-customer-servicios flex w-[calc(150px*20*2)] space-x-8">
                        {imagesCustomersServicios.map((logo, index) => (
                            <div
                                className="slide flex w-[150px] items-center justify-center"
                                key={index}
                            >
                                <img src={logo} className="max-w-full h-auto" alt={`customer-logo-${index}`} />
                            </div>
                        ))}
                        {imagesCustomersServicios.map((logo, index) => (
                            <div
                                className="slide flex w-[150px] items-center justify-center"
                                key={index}
                            >
                                <img src={logo} className="max-w-full h-auto" alt={`customer-logo-${index}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="relative md:py-8 py-8  bg-white dark:bg-slate-800 active" id="customers">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Sector Financiero</h3>
                    </div>
                </div>
                <div className="relative m-auto w-[60%] overflow-hidden bg-white before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] after:content-['']">
                    <div className="animate-infinite-slider-customer-financiero flex w-[calc(150px*15*2)] space-x-8">
                        {imagesCustomersFinanciero.map((logo, index) => (
                            <div
                                className="slide flex w-[150px] items-center justify-center"
                                key={index}
                            >
                                <img src={logo} className="max-w-full h-auto" alt={`customer-logo-${index}`} />
                            </div>
                        ))}
                        {imagesCustomersFinanciero.map((logo, index) => (
                            <div
                                className="slide flex w-[150px] items-center justify-center"
                                key={index}
                            >
                                <img src={logo} className="max-w-full h-auto" alt={`customer-logo-${index}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="relative md:py-8 py-8  bg-white dark:bg-slate-800 active" id="customers">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Sector Educativo</h3>
                    </div>
                </div>
                <div className="relative m-auto w-[60%] overflow-hidden bg-white before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] after:content-['']">
                    <div className="animate-infinite-slider-customer-educativo flex w-[calc(150px*15*2)] space-x-8">
                        {imagesCustomersEducativo.map((logo, index) => (
                            <div
                                className="slide flex w-[150px] items-center justify-center"
                                key={index}
                            >
                                <img src={logo} className="max-w-full h-auto" alt={`customer-logo-${index}`} />
                            </div>
                        ))}
                        {imagesCustomersEducativo.map((logo, index) => (
                            <div
                                className="slide flex w-[150px] items-center justify-center"
                                key={index}
                            >
                                <img src={logo} className="max-w-full h-auto" alt={`customer-logo-${index}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="relative md:py-8 py-8  bg-white dark:bg-slate-800 active" id="customers">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Gremios / Fundaciones</h3>
                    </div>
                </div>
                <div className="relative m-auto w-[60%] overflow-hidden bg-white before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] after:content-['']">
                    <div className="animate-infinite-slider-customer-gremios flex w-[calc(150px*16*2)] space-x-8">
                        {imagesCustomersGremios.map((logo, index) => (
                            <div
                                className="slide flex w-[150px] items-center justify-center"
                                key={index}
                            >
                                <img src={logo} className="max-w-full h-auto" alt={`customer-logo-${index}`} />
                            </div>
                        ))}
                        {imagesCustomersGremios.map((logo, index) => (
                            <div
                                className="slide flex w-[150px] items-center justify-center"
                                key={index}
                            >
                                <img src={logo} className="max-w-full h-auto" alt={`customer-logo-${index}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="relative md:py-8 py-8  bg-white dark:bg-slate-800 active" id="customers">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Sector Público</h3>
                    </div>
                </div>
                <div className="relative m-auto w-[60%] overflow-hidden bg-white before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] after:content-['']">
                    <div className="animate-infinite-slider-customer-comercial flex w-[calc(150px*12*2)] space-x-8">
                        {imagesCustomersPublico.map((logo, index) => (
                            <div
                                className="slide flex w-[150px] items-center justify-center"
                                key={index}
                            >
                                <img src={logo} className="max-w-full h-auto" alt={`customer-logo-${index}`} />
                            </div>
                        ))}
                        {imagesCustomersPublico.map((logo, index) => (
                            <div
                                className="slide flex w-[150px] items-center justify-center"
                                key={index}
                            >
                                <img src={logo} className="max-w-full h-auto" alt={`customer-logo-${index}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}
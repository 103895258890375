import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Typed from "typed.js";

import BackgroudImage from "../assets/images/bg/1.jpg";
import Services from "../component/Services";
import About from "../component/About";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Portfolio from "../component/Portfolio";

import Navbar from "../component/navbar";

export default function Index() {
  const isDetailPage = false;

  const location = useLocation(); // Obtén la ubicación actual

  useEffect(() => {
    const handleHashChange = () => {
      const sectionId = location.hash.replace("#", ""); // Obtén el ID de la sección
      const section = document.getElementById(sectionId);

      if (section) {
        const navbar = document.querySelector("nav");
        const headerOffset = navbar ? navbar.offsetHeight : 0;

        const elementPosition = section.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    };

    // Manejar el desplazamiento al cargar el componente
    handleHashChange();

    // Escuchar cambios en el hash
    window.addEventListener("hashchange", handleHashChange);

    // Limpiar el evento al desmontar
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [location]); // Ejecutar cada vez que la ubicación cambie



  setTimeout(() => {
    new Typed("#typed", {
      strings: [
        "<b>SÍ funcionan para tu Empresa</b>",
        "<b>Transforman tu Negocio</b>",
        "<b>Generan Valor y Crecimiento</b>",
      ],
      backDelay: 500,
      loop: true,
      startDelay: 200,
      typeSpeed: 30,
      backSpeed: 30,
    });
  }, 500);

  return (
    <>
      <div>
        <Navbar isDetailPage={isDetailPage} />
        <section
          style={{ backgroundImage: `url(${BackgroudImage})` }}
          className="py-36 lg:py-64 w-full table relative bg-center bg-cover"
          id="home"
        >
          <div className="absolute inset-0 bg-black opacity-80"></div>
          <div className="container relative">
            <div className="grid grid-cols-1 mt-12">
              <h4 className="text-white text-2xl sm:text-3xl md:text-3xl lg:text-3xl font-medium mb-7 position-relative">
                Estrategias que <br /> {" "}
                <span
                  className="typewrite relative text-type-element"
                  id="typed"
                ></span>
              </h4>

              <p className="text-white opacity-80 mb-0 max-w-2xl text-lg">
                Con nuestra experiencia en la creación e implementación de estrategias y sistemas de gestión, guiamos a tu empresa hacia el éxito,
                garantizando el enfoque, una alineación perfecta de tu equipo con los objetivos y un aumento en el desempeño empresarial.
              </p>

              <div className="relative mt-10">
                <a href="#contact" className="btn bg-color-arco-dark hover:bg-color-arco-light border-color-arco-dark hover:border-color-arco-light text-white rounded-md">Contáctanos</a>
              </div>
            </div>
          </div>
        </section>

        <About />
        <Services />
        <Portfolio />
        <Contact />
        <Footer />
      </div>

    </>
  );

}


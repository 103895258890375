import React, { useState } from 'react';

export default function Contact() {
    const [formData, setFormData] = useState({
        nombres: '',
        apellidos: '',
        cargo: '',
        empresa: '',
        email: '',
        telefono: '',
        mensaje: ''
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData({
            ...formData,
            [name]: newValue,
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://soe-api-email-production.up.railway.app/send-email-arco', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                alert('¡Mensaje enviado con éxito!');
            } else {
                alert('Hubo un error al enviar el mensaje. Por favor, inténtalo de nuevo más tarde.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Hubo un error al enviar el mensaje. Por favor, inténtalo de nuevo más tarde.');
        }
    };

    return (
        <>
            {/* Start */}
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="contact">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="text-color-arco-dark
 text-base font-medium uppercase mb-2">Conéctate con nosotros y potencia tu estrategia empresarial</h3>
                        {/* <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Transforma tu estrategía hoy!</h3> */}

                        <p className="text-zinc-800 dark:text-slate-300 max-w-xl mx-auto">
                            Estamos aquí para ayudarte a transformar y fortalecer tu estrategia.
                            Contáctanos y descubre cómo podemos colaborar para llevar tu empresa
                            hacia el éxito con asesorías personalizadas y de alto impacto.
                        </p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-6">
                        <div className="lg:col-span-8">
                            <div className="p-6 rounded-md shadow bg-white dark:bg-slate-900">
                                <form onSubmit={handleSubmit}>
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <input name="nombres" id="name" type="text" className="form-input" placeholder="Nombres :" value={formData.nombres}
                                                onChange={handleChange} />
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                            <input name="apellidos" id="name" type="text" className="form-input" placeholder="Apellidos :" value={formData.apellidos}
                                                onChange={handleChange} />
                                        </div>
                                    </div>

                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <input name="cargo" id="cargo" type="text" className="form-input" placeholder="Cargo :" value={formData.cargo}
                                                onChange={handleChange} />
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                            <input name="email" id="email" type="email" className="form-input" placeholder="Email :" value={formData.email}
                                                onChange={handleChange} />
                                        </div>
                                    </div>

                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <input name="empresa" id="empresa" type="text" className="form-input" placeholder="Empresa :" value={formData.empresa}
                                                onChange={handleChange} />
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                            <input name="telefono" id="telefono" type="number" className="form-input" placeholder="Teléfono :" value={formData.telefono}
                                                onChange={handleChange} />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <textarea name="mensaje" id="mensaje" className="form-input textarea h-28" placeholder="Mensaje :" value={formData.mensaje}
                                                onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                    <button type="submit" id="submit" name="send" className="btn bg-color-arco-light hover:bg-color-arco-dark border-color-arco-light hover:border-color-arco-dark text-white rounded-md h-11 justify-center flex items-center">Enviar</button>
                                </form>
                            </div>
                        </div>

                        <div className="lg:col-span-4">
                            <div className="lg:ms-8">
                                <div className="flex">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-phone block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">Teléfono</h5>
                                        <a href="tel:+152534-468-854" className="text-zinc-800">+593 987674878</a>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-envelope block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">Email</h5>
                                        <a href="mailto:contact@example.com" className="text-zinc-800">alesaltos@arcoestrategias.com</a>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">Localización</h5>
                                        <p className="text-zinc-800 mb-2">Puerto Santa Ana - Edificio The Point Piso 5 Oficina 502</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

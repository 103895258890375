import React, { useState } from "react";
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import { Link } from 'react-router-dom';
import Customers from "./Customers";

import Image1 from "../assets/images/portfolio/logo/logo-grande/sambito.webp";
import Image2 from "../assets/images/portfolio/logo/logo-grande/ponce-3.webp";
import Image3 from "../assets/images/portfolio/logo/logo-grande/semgroup.webp";
import Image4 from "../assets/images/portfolio/logo/logo-grande/torres-y-torres.webp";
import Image5 from "../assets/images/portfolio/5.jpg";
import Image6 from "../assets/images/portfolio/6.jpg";
import Image7 from "../assets/images/portfolio/7.jpg";
import Image8 from "../assets/images/portfolio/8.jpg";



import CTABackground from "../assets/images/bg/cta.png";

const images = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8
];



/**
 * Portfolio section
 */
export default function Portfolio() {

    const [photoIndex, setActiveIndex] = useState(0);
    const [isOpen, setOpen] = useState(false);

    // const handleCLick = (index) => {
    //     setActiveIndex(index)
    //     setOpen(true);
    // }

    const projectList = [
        {
            id: 'sambito',
            image: Image1,
            title: 'Plan Estratégico',
            subtext: 'Sambito'
        },
        {
            id: 'ponce-3',
            image: Image2,
            title: 'Modelo de Negocio y Plan Estratégico',
            subtext: 'Ponce 3'
        }, {
            id: 'semgroup',
            image: Image3,
            title: 'Plan Estratégico',
            subtext: 'Semgroup'
        }, {
            id: 'torres',
            image: Image4,
            title: 'Plan Estratégico',
            subtext: 'Torres y Torres'
        }
    ]
    return (
        <>
            {/* Project Start  */}
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800 active" id="portfolio">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h6 className="text-color-arco-dark
 text-base font-medium uppercase mb-2">Casos de Éxito</h6>
                        {/* <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Nuestros Proyectos</h3> */}

                        <p className="text-zinc-800 dark:text-slate-300 max-w-xl mx-auto">Desarrolla tu estrategia y aprovecha nuestra experiencia en la creación de estrategias gerenciales y modelos de negocio que impulsan el crecimiento y aseguran resultados sostenibles.</p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-6 mt-8">
                        {projectList.map((item, index) => (
                            <Link to={`/portfolio-detail-${item.id}`} key={index} className="relative rounded-md shadow-sm overflow-hidden group">
                                <div className="relative">
                                    <img src={item.image} className="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500" alt="workimage" />
                                    <div className="absolute inset-0 group-hover:bg-black opacity-50 transition duration-500 z-0"></div>

                                    <div className="content">
                                        <div className="absolute z-10 opacity-0 group-hover:opacity-100 bottom-4 start-4 transition-all duration-500">
                                            <span className="h6 text-md font-medium text-white">{item.title}</span>
                                            <p className="text-slate-100 tag mb-0">{item.subtext}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>

                    {isOpen && (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                            onCloseRequest={() => setOpen({ isOpen: false })}
                            onMovePrevRequest={() =>
                                setActiveIndex((photoIndex + images.length - 1) % images.length,
                                )
                            }
                            onMoveNextRequest={() =>
                                setActiveIndex((photoIndex + 1) % images.length,
                                )
                            }
                        />
                    )}
                </div>
            </section>

            <Customers/>


            {/* Project End  */}
            <section
                style={{ backgroundImage: `url(${CTABackground})` }}
                className="py-24 w-full table relative bg-center bg-cover">
                <div className="absolute inset-0 bg-black opacity-80"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl text-white font-medium">¡Contáctanos hoy y descubre cómo podemos llevar tu negocio al siguiente nivel!</h3>

                        {/* <p className="text-white opacity-80 max-w-xl mx-auto">¡Contáctanos hoy y descubre cómo podemos llevar tu negocio al siguiente nivel!.</p> */}

                        <div className="relative mt-10">
                            <a href="#contact" className="btn bg-color-arco-light hover:bg-color-arco-dark border-color-arco-light hover:border-color-arco-dark text-white rounded-md">Contáctanos</a>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );

}
